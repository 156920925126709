<template>
  <div role="main" class="main">
    <header class="header">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12">
            <nav class="navbar navbar-expand-md navbar-dark pl-lg-0">
              <a class="navbar-brand" href="javascript:void(0);">{{
                event ? event.decease_name : "MOURN WITH US"
              }}</a>
              <div class="navbar-list">
                <router-link
                  tag="a"
                  class="text-yellow hover-yellow mr-3 mr-md-5"
                  to="/speaker/profile"
                  >Profile</router-link
                >
                <a
                  href="#"
                  class="text-yellow hover-yellow mr-3 mr-md-5"
                  @click="moveLive"
                  >Live</a
                >
              </div>
              <a
                href="#"
                class="text-yellow hover-yellow logout"
                @click="logout"
                ><i class="fas fa-sign-out-alt"></i>Logout</a
              >
            </nav>
          </div>
        </div>
      </div>
    </header>
    <section class="common-page minh-100 guestbook-registration image-page">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-9">
            <div class="border-l text-left">
              <p>Profile</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-3">
            <h4 class="text-yellow mb-1">Edit your profile</h4>
          </div>
          <div class="col-12 col-md-6">
            <div class="custom-group">
              <div class="photo-preview d-flex align-items-center">
                <div class="upload-photo mr-3" v-if="!image_url">
                  Your photo in here
                </div>
                <div
                  class="flower mr-3"
                  v-if="image_url"
                  style="margin-bottom: 0.5rem"
                >
                  <img :src="image_url" class="img-fluid round-image" />
                </div>
                <div class="photo-action text-left">
                  <input
                    type="file"
                    ref="avatarFile"
                    @change="inputFile"
                    accept="image/x-png,image/gif,image/jpeg"
                    style="display: none"
                  />
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="$refs.avatarFile.click()"
                  >
                    Upload your picture
                  </button>
                  <h6 class="text-primary mt-2">
                    Or select and photo profile from the photos on the right
                  </h6>
                </div>
              </div>
              <div class="mt-3">
                <b-button
                  type="button"
                  class="btn-primary mr-3"
                  @click="save"
                  v-if="submit_btn"
                  >Submit</b-button
                >
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower1.png')"
                  class="img-fluid"
                  @click="setImage('flower1.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower2.png')"
                  class="img-fluid"
                  @click="setImage('flower2.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower3.png')"
                  class="img-fluid"
                  @click="setImage('flower3.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower4.png')"
                  class="img-fluid"
                  @click="setImage('flower4.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower5.png')"
                  class="img-fluid"
                  @click="setImage('flower5.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower6.png')"
                  class="img-fluid"
                  @click="setImage('flower6.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower7.png')"
                  class="img-fluid"
                  @click="setImage('flower7.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower8.png')"
                  class="img-fluid"
                  @click="setImage('flower8.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower9.png')"
                  class="img-fluid"
                  @click="setImage('flower9.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower10.png')"
                  class="img-fluid"
                  @click="setImage('flower10.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower11.png')"
                  class="img-fluid"
                  @click="setImage('flower11.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower12.png')"
                  class="img-fluid"
                  @click="setImage('flower12.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower13.png')"
                  class="img-fluid"
                  @click="setImage('flower13.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower14.png')"
                  class="img-fluid"
                  @click="setImage('flower14.png')"
                />
              </a>
            </div>
            <div class="flower">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower15.png')"
                  class="img-fluid"
                  @click="setImage('flower15.png')"
                />
              </a>
            </div>
            <div class="flower mb-lg-0">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower16.png')"
                  class="img-fluid"
                  @click="setImage('flower16.png')"
                />
              </a>
            </div>
            <div class="flower mb-lg-0">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower17.png')"
                  class="img-fluid"
                  @click="setImage('flower17.png')"
                />
              </a>
            </div>
            <div class="flower mb-lg-0">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower18.png')"
                  class="img-fluid"
                  @click="setImage('flower18.png')"
                />
              </a>
            </div>
            <div class="flower mb-lg-0">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower19.png')"
                  class="img-fluid"
                  @click="setImage('flower19.png')"
                />
              </a>
            </div>
            <div class="flower mb-lg-0">
              <a href="javascript:void(0);">
                <img
                  :src="require('@/assets/img/flower20.png')"
                  class="img-fluid"
                  @click="setImage('flower20.png')"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { getApiManager } from "../../api";
import { apiBaseUrl, liveBaseUrl } from "../../constants/config";

export default {
  data() {
    return {
      profile: {
        image: null,
        type: "default",
        profile_url: "",
      },
      image_url: "",
      event: null,
      submit_btn: null,
    };
  },
  mounted() {
    getApiManager()
      .post(`${apiBaseUrl}/api/speaker/profile/modifylive`, {
        live: 0,
        content_type_id: this.content_type_id,
      })
      .then((response) => {});
    this.connectSocket();
    getApiManager()
      .post(`${apiBaseUrl}/api/speaker/profile/get-info`)
      .then((response) => {
        let res = response.data;
        if (res.status === true) {
          this.image_url = `${apiBaseUrl}/` + res.detail.profile_url;
          this.profile.profile_url = "";
        } else {
          if (res.error == null) {
            this.$notify({
              group: "foo",
              type: "error",
              title: "Error",
              text: "Server Error",
              animation_type: "slide",
            });
          }
        }
      })
      .catch((error) => {});
    getApiManager()
      .post(`${apiBaseUrl}/api/speaker/event/get`)
      .then((response) => {
        let res = response.data;
        if (res.status === true) {
          let detail = res.detail;
          this.event = detail;
          // if (
          //   localStorage.getItem("Live_id") &&
          //   localStorage.getItem("Live_id") != null &&
          //   localStorage.getItem("Live_id") != "null"
          // ) {
          //   localStorage.setItem("Live_id", null);
          //   getApiManager()
          //     .post(`${apiBaseUrl}/api/admin/event/reset`, {
          //       event_id: this.event.event_id,
          //     })
          //     .then((response) => {
          //       console.log("ALSLSLSLS");
          //     });
          // }
        }
        getApiManager()
          .post(`${apiBaseUrl}/api/vonage/getToken`, {
            decease_name: this.event.decease_name,
          })
          .then((result) => {
            if (
              result.data.data[0].sessionId == null ||
              result.data.apiKey == null ||
              result.data.data[0].token == null
            ) {
              getApiManager()
                .post(`${apiBaseUrl}/api/vonage/generateToken`, {
                  decease_name: this.event.decease_name,
                })
                .then((result) => {});
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
  },
  methods: {
    ...mapActions(["signOut"]),
    connectSocket() {
      var ref = this;

      this.connection = new WebSocket(liveBaseUrl);
      this.connection.onmessage = function (event) {
        //this.isLoading = false;
        let data = JSON.parse(event.data);
        if (data.type == "start_service") {
          console.log("{{{{{{startservice");
          let randomnumber = Math.floor(Math.random() * 10000) + 1;
          ref.$router.push("/speaker/whereby?x=" + randomnumber);
        }
      };

      this.connection.onclose = (e) => {
        console.log(
          "Socket is closed. Reconnect will be attempted in 1 second."
        );
        setTimeout(() => {
          ref.connectSocket();
        }, 1000);
      };
    },
    logout() {
      if (self.OTSession) {
        self.OTSession.disconnect();
      }
      getApiManager()
        .post(`${apiBaseUrl}/api/speaker/profile/modifylive`, {
          live: 0,
          content_type_id: this.content_type_id,
        })
        .then((response) => {
          localStorage.setItem("Live_id", null);
          localStorage.setItem("deceased_name",'')
          this.signOut();
          this.$router.push("/login");
          window.location.reload();
        });
    },
    setImage(item) {
      this.profile.type = "default";
      this.profile.profile_url = item;
      this.profile.image = null;
      //this.submit_btn = "allow";
      this.image_url = require("@/assets/img/" + item);

      if (this.profile.profile_url == "" && this.profile.image == null) {
        this.$notify({
          group: "foo",
          type: "warn",
          title: "Error",
          text: "Please select an image.",
          animation_type: "slide",
        });
        return;
      }
      const formData = new FormData();
      for (let key in this.profile) {
        formData.append(key, this.profile[key]);
      }
      getApiManager()
        .post(`${apiBaseUrl}/api/speaker/profile/modify`, formData)
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            this.$notify({
              group: "foo",
              type: "success",
              title: "Success",
              text: "The profile was saved successfully.",
              animation_type: "slide",
            });
            return;
          } else {
            this.$notify({
              group: "foo",
              type: "warn",
              title: "Error",
              text: "Server Error",
              animation_type: "slide",
            });
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    inputFile(event) {
      this.profile.type = "random";
      this.profile.image = event.target.files[0];
      //this.submit_btn = "allow";
      //set image to the div area
      var reader = new FileReader();
      var ref = this;
      reader.onload = function (e) {
        ref.image_url = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
      if (this.profile.profile_url == "" && this.profile.image == null) {
        this.$notify({
          group: "foo",
          type: "warn",
          title: "Error",
          text: "Please select an image.",
          animation_type: "slide",
        });
        return;
      }
      const formData = new FormData();
      for (let key in this.profile) {
        formData.append(key, this.profile[key]);
      }
      getApiManager()
        .post(`${apiBaseUrl}/api/speaker/profile/modify`, formData)
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            this.$notify({
              group: "foo",
              type: "success",
              title: "Success",
              text: "The profile was saved successfully.",
              animation_type: "slide",
            });
            return;
          } else {
            this.$notify({
              group: "foo",
              type: "warn",
              title: "Error",
              text: "Server Error",
              animation_type: "slide",
            });
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    moveLive() {
      getApiManager()
        .post(`${apiBaseUrl}/api/speaker/event/get`)
        .then((response) => {
          let res = response.data;
          console.log(res);
          if (res.status === true) {
            let detail = res.detail;

            console.log(
              "%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%",
              detail.status,
              localStorage.getItem("Live_id")
            );
            getApiManager()
              .post(`${apiBaseUrl}/api/speaker/all`)
              .then((response) => {
                let res = response.data;
                if (res.status === true) {
                  this.speakers = res.detail.speakers;
                  // for (var i = 0; i < this.speakers.length; i++) {
                  //   if (this.speakers[i].speaker_type == 0) {
                  //     getApiManager()
                  //       .post(`${apiBaseUrl}/api/speaker/profile/modifylive`, {
                  //         live: 1,
                  //         content_type_id: this.speakers[i].content_type_id,
                  //       })
                  //       .then((response) => {});
                  //   }
                  // }
                }
              });

            if (detail.status == 0) {
              if (self.OTSession) {
                self.OTSession.disconnect();
              }
              let randomnumber = Math.floor(Math.random() * 10000) + 1;
              this.$router.push("/speaker/wait?ee=" + randomnumber);
              // this.$router.push("/speaker/wait");
            } else if (detail.status == 1) {
              console.log("&&&&&&&&&&&&&", this.content_type_id);
              getApiManager()
                .post(`${apiBaseUrl}/api/speaker/profile/modifylive`, {
                  live: 1,
                  content_type_id: this.content_type_id,
                })
                .then((response) => {});
              let randomnumber = Math.floor(Math.random() * 10000) + 1;
              this.$router.push("/speaker/start/"+this.event.event_id+"?x=" + randomnumber);
            } else if (detail.status == 2) {
              getApiManager()
                .post(`${apiBaseUrl}/api/speaker/profile/modifylive`, {
                  live: 1,
                  content_type_id: this.content_type_id,
                })
                .then((response) => {});
              let randomnumber = Math.floor(Math.random() * 10000) + 1;
              this.$router.push("/speaker/live/"+this.event.event_id+"?x=" + randomnumber);
            } else if (detail.status == 3) {
              this.$notify({
                group: "foo",
                type: "error",
                title: "Finished",
                text: "The event was already finished.",
                animation_type: "slide",
              });
            }
          } else {
            if (res.error == null) {
              this.$notify({
                group: "foo",
                type: "error",
                title: "Error",
                text: "Server Error",
                animation_type: "slide",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    save() {
      if (this.profile.profile_url == "" && this.profile.image == null) {
        this.$notify({
          group: "foo",
          type: "warn",
          title: "Error",
          text: "Please select an image.",
          animation_type: "slide",
        });
        return;
      }
      const formData = new FormData();
      for (let key in this.profile) {
        formData.append(key, this.profile[key]);
      }
      getApiManager()
        .post(`${apiBaseUrl}/api/speaker/profile/modify`, formData)
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            this.$notify({
              group: "foo",
              type: "success",
              title: "Success",
              text: "The profile was saved successfully.",
              animation_type: "slide",
            });
            return;
          } else {
            this.$notify({
              group: "foo",
              type: "warn",
              title: "Error",
              text: "Server Error",
              animation_type: "slide",
            });
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  components: {},
  computed: {},
  watch: {},
};
</script>
